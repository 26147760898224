import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setAutoSpinsLeft,
  setBrokenGame,
  setCurrentBonus,
  setIsAutoSpins,
  setIsBuyFeaturePopupOpened,
  setIsLeftHandMode,
  setIsMobile,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setSlotMachineInitialized,
} from '../../gql/cache';
import { calcPercentage, getGameModeByBonusId, isCroonBonusMode } from '../../utils';
import { eventManager } from '../config';

import Button from '.';

class AutoplayBtn extends Button {
  private gameMode: GameMode;

  private isAutoSpins: boolean;

  private isSpinInProgress: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  private isPopupFreeRoundsOpened: boolean;

  constructor() {
    super('auto-play', 60);
    this.interactive = !this.isDisabled;
    this.gameMode = setBrokenGame() ? getGameModeByBonusId(setCurrentBonus().bonusId) : GameMode.REGULAR;
    this.isAutoSpins = false;
    this.isSpinInProgress = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;
    this.isPopupFreeRoundsOpened = false;

    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_UPDATE_BTN, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREE_SPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.UI_VISIBLE, () => {
      this.visible = !this.visible;
    });
    eventManager.on(EventTypes.CROON_BONUS_END, () => {
      this.isSpinInProgress = false;
    });
    eventManager.addListener(EventTypes.FORCE_STOP_AUTOPLAY, () => {
      if (this.isAutoSpins) {
        setAutoSpinsLeft(0);
        setIsAutoSpins(false);
        this.isAutoSpins = false;
      }
    });
    eventManager.on(EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED, (isPopupFreeRoundsOpened: boolean) => {
      this.isPopupFreeRoundsOpened = isPopupFreeRoundsOpened;
      this.handleDisable();
    });
  };

  private handleClick = (): void => {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE && setSlotMachineInitialized()) {
      setIsModalOpeningInProgress(ModalOpeningTypes.AUTOPLAY);

      setIsOpenAutoplayModal(!setIsOpenAutoplayModal());
      setIsOpenMenuModal({ isOpen: false, type: 'menu' });
      setIsOpenBetSettingsModal(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      if (setIsMobile() && setIsLeftHandMode()) {
        eventManager.emit(EventTypes.TOGGLE_UI_LEFT);
      } else {
        eventManager.emit(EventTypes.TOGGLE_UI_RIGHT);
      }
      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  };

  private handleDisable = (): void => {
    this.setDisable(
      isCroonBonusMode(this.gameMode) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isFreeSpinsWin ||
        this.isSlotBusy ||
        this.isPopupFreeRoundsOpened ||
        setIsBuyFeaturePopupOpened(),
    );
  };

  public override handlePosition(): void {
    let x = this.isLeftHandMode
      ? calcPercentage(this.applicationSize.width, 4)
      : calcPercentage(this.applicationSize.width, 96);
    let y = calcPercentage(this.applicationSize.height, 95);
    this.setSize(calcPercentage(this.applicationSize.width, 4));

    if (this.isPortraitMode) {
      this.setSize(calcPercentage(this.underGameContainerSpaceHeight, 20));
      x = this.isLeftHandMode
        ? calcPercentage(this.underGameContainerSpaceHeight, 20)
        : this.applicationSize.width - calcPercentage(this.underGameContainerSpaceHeight, 20);
      y = this.gameContainerBottomPosition + calcPercentage(this.underGameContainerSpaceHeight, 80);
    }

    if (this.isLandscapeMode) {
      this.setSize(calcPercentage(this.applicationSize.height, 10.4));
      x = this.isLeftHandMode
        ? calcPercentage(this.applicationSize.width, 9)
        : calcPercentage(this.applicationSize.width, 91);
      y = calcPercentage(this.applicationSize.height, 87.5);
    }

    this.x = x;
    this.y = y;
  }
}

export default AutoplayBtn;
